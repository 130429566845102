import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Alert, AlertTitle } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    mainPhoto: {
        position: 'relative',
        maxWidth: '100%',
        height: 'auto',
    },
    section: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}))

function Home() {
    const classes = useStyles();
    return (
        <Container className={classes.root} container="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" align="center" gutterBottom>
                    Welcome to Andresen Apiaries!
                </Typography>

                <br />

                <Alert severity="warning">
                    Andresen Apiaries - Temporary Closure
                    <p>
                        Andresen Apiaries is temporarily closing down. We have a small stock of Nuc boxes, entrance reducers, and hive tops and bottoms available. Please contact us for more information.
                    </p>
                    <p>
                        We will keep you updated on when we will be building again in the future! Thank you!
                    </p>
                </Alert>

                <Grid className={classes.section} container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/home-page.JPG" alt="bee-crossing" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/andresen-family-hp.JPG" alt="bee-crossing" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/home-page-2.JPG" alt="bee-crossing" />
                    </Grid>
                </Grid>

                <div className={classes.section}>
                    <Typography variant="body1" gutterBottom>
                        Andresen Apiaries is a locally owned and operated business that is committed to providing you with quality
                        beekeeping equipment made in Strathcona Country, Alberta.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        We are family-oriented folks who love spending time in the bee yard and are passionate about bees, our business, and helping other
                        beekeepers. We definitely don't know everything, but we hope that we can share our experiences and knowledge with all of you!
                        Take a look around the website and please contact us with any questions!
                    </Typography>
                </div>

                <Typography variant="h5" align="center" gutterBottom>
                    Thanks for stopping by!
                </Typography>
            </div>
        </Container>
    );
}

export default Home;
